<template>
  <div
    data-name="app-header"
    class="d-flex flex-column flex-md-row align-items-md-center align-items-stretch"
  >
    <div
      class="py-1 py-md-2 px-2 px-md-3 d-flex"
      :style="{
        'min-width': navCompact ? 0 : '280px',
        transition: 'all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1)'
      }"
    >
      <alchemy-button
        variant="outline-primary"
        dataName="nav-hamberger"
        @click="toggleMobileMenuVisible"
        class="mr-2 alchemy-app-navbar-toggle"
        data-info="mobile hamberger"
        :class="navbarToggleClasses"
      >
        <alchemy-icon icon="bars" />
        <span class="sr-only">Menu</span>
      </alchemy-button>

      <span class="pl-2">
        <img
          :src="logo"
          alt="logo"
          style="object-fit: contain; max-width: 100%; min-height: 36px; max-height: 36px"
          v-on:load="objectFitPoly()"
        />
      </span>
    </div>
    <div
      class="d-flex px-3 px-md-4 py-2 py-md-3 align-items-center flex-grow-1 app-header"
      :class="{
        'header-not-compact': !navCompact,
        'pl-md-0': navCompact
      }"
    >
      <alchemy-heading
        :level="5"
        class="mb-0 flex-grow-1 font-weight-normal"
        data-name="pageHeading"
        :manualColors="true"
        >{{ activeRouteName }}</alchemy-heading
      >
      <div class="d-flex">
        <div
          class="mr-2"
          v-if="owningEntity.type === 'company'"
          data-name="owning-entity-reference"
        >
          <small>{{ owningEntity.reference }}</small>
        </div>
        <alchemy-heading
          v-else
          :level="6"
          :manualColors="true"
          class="text-capitalize mb-0 flex-grow-1 mr-2"
          dataName="owning-entity-reference"
          ><small>{{ owningEntity.reference }}</small></alchemy-heading
        >
        <alchemy-data-dropdown
          v-if="owningEntity.type === 'company'"
          class="mb-1"
          dataName="siteDropdown"
          :rootEntityUuid="owningEntity.uuid"
          listFunctionName="list-sites"
          :allItem="showAllSites ? { ...owningEntity, label: 'All Sites' } : undefined"
          :initialEntityReference="initialRootEntityRef"
          :saveToLocalStorage="true"
          :buttonText="selectedSiteLabel"
          itemsHeader="RECENTLY VIEWED"
          @change="setSelectedSite"
        />
        <alchemy-heading
          v-else
          :level="6"
          class="text-capitalize mb-0 flex-grow-1"
          dataName="root-entity-name"
          :manualColors="true"
          ><small>{{ selectedRootEntityName }}</small></alchemy-heading
        >
      </div>
      <support-link class="ml-3" />
      <user-profile-dropdown class="ml-3" />
    </div>
  </div>
</template>

<script>
import { iconApi } from "@encapto/alchemy-gui";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import _ from "lodash";
import objectFitImages from "object-fit-images";
import { AlchemyDataDropdown } from "@encapto/alchemy-components";
import { NAV_TO_SITE } from "../store/actions";
import {
  BRANDING,
  SELECTED_ROOT_ENTITY,
  OWNING_ENTITY,
  ALL_SITES_VISIBLE,
  ROUTE_ROOT_REFERENCE
} from "../store/getters";
import UserProfileDropdown from "./UserProfileDropdown.vue";
import SupportLink from "./SupportLink.vue";

iconApi.library.add(faBars);

export default {
  name: "app-header",
  components: {
    UserProfileDropdown,
    SupportLink,
    AlchemyDataDropdown
  },
  props: {
    navCompact: {
      type: Boolean
    },
    activeRouteName: {
      type: String,
      default: ""
    }
  },
  emits: ["toggleMobileMenuVisible"],
  computed: {
    ...mapGetters({
      branding: BRANDING,
      selectedRootEntity: SELECTED_ROOT_ENTITY,
      owningEntity: OWNING_ENTITY,
      showAllSites: ALL_SITES_VISIBLE,
      initialRootEntityRef: ROUTE_ROOT_REFERENCE
    }),
    navbarToggleClasses() {
      const classes = {
        "mobile-controls": true
      };
      if (this.$skin && this.$skin.getSkinClass("alchemy-app-navbar-toggle")) {
        classes[this.$skin.getSkinClass("alchemy-app-navbar-toggle")] = true;
      }
      return classes;
    },
    logo() {
      return _.get(this.branding, "logo");
    },
    selectedSiteLabel() {
      if (!this.selectedRootEntity) return "ALL SITES";
      if (this.selectedRootEntity.type === "company") return "ALL SITES";
      return this.selectedRootEntityName;
    },
    selectedRootEntityName() {
      return _.get(
        this.selectedRootEntity,
        "configuration.name",
        _.get(this, "selectedRootEntity.name")
      );
    }
  },
  methods: {
    toggleMobileMenuVisible(mobileMenuVisible) {
      this.$emit("toggleMobileMenuVisible", mobileMenuVisible);
    },
    objectFitPoly() {
      // polyfill for IE11 and older browsers for object-fit
      objectFitImages();
    },
    setSelectedSite(site) {
      this.$store.dispatch(NAV_TO_SITE, { site, router: this.$router });
    }
  }
};
</script>

<style lang="scss" scoped></style>
